import './Home.css'
import './Navbar.css'

function Home(){
    setTimeout(() => {
        
    






var scrollTop = window.scrollY




const menuBtn = document.querySelector('.menu-btn');
let menuOpen = false;

menuBtn.addEventListener('click', () => {
    if(!menuOpen) {
        menuBtn.classList.add('open');
        menuOpen = true;
    } else {
        menuBtn.classList.remove('open');
        menuOpen = false;
}
});

const anchor = document.getElementById('anchor');
const menu = document.getElementById('menu');
console.log(menu.style.display == '')

anchor.addEventListener('click', () => {
    console.log('clicked')
    if (menu.style.display == 'flex') {
        menu.style.display = '';
        console.log('removed')
    }
    else if(menu.style.display == '') {
        menu.style.display = 'flex';
        console.log('displayed')
    }

})
}, 100);
    return(
        <div>
            <header>
            <nav>
            <input type="checkbox" id="check" />
            <label className="menu-btn" for="check">
                <div className="menu-btn__burger"></div>
            </label>
            <ul className="nav_links">
                <li className="li"><a className="hyper special" href="index.html"><img className="specimg" src="images/EBC_Logo.png" alt="" /></a></li>
                <li><a href="/">Accueil</a></li>
                <li className="dropli">
                    <div className="dropdown" id="anchor">
                        <a className="anchor">
                            Nos produits
                        </a>
                        <div className="menu" id="menu">
                            <div className="column">
                                <a href="verandas">Vérandas</a>
                                <a href="maconneries">Maçonneries<br></br>Gros-oeuvre</a>
                                <a href="stores">Stores</a>
                            </div>
                            <div className="column">
                                <a href="pergolas">Pergolas</a>
                                <a href="extensions">Extensions &<br />rénovations maison</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li><a href="About">À propos</a></li>
                <li><a href="realisations">Nos réalisations</a></li>
                <li><a href="Contact">Contact</a></li>
                <li className="img"></li>
            </ul>
            <div className="img1"></div>
        </nav>
        <div className="banderole">
            <div className="jvenm">devis & conseils gratuits</div>
            <div className="jvenm2">projets personalisés</div>
        </div>
    </header>
    
    <a className='form' href='contact'></a>

    <section className="head">

        <div className="background_home">
            <img className="back-img" src="images/d.png" alt="" />
            <div className="header">
                <h1 className="nom">NKI</h1>
                <div className="separe-titlel"></div>
                <h1 className="slogan">VOTRE ESPACE EXTERIEUR SUR MESURE</h1>
            </div>
        </div>


        <img className="back-img" src="images/background." alt="" />
    </section>
    <section className="rest">

        <div className="activite" id="activite">
            <a href="verandas">
                <div className="produit p dix" id="un">
                    <div id="div-expli" className="div-expli">
                        <h3 id="h3-expli">Vérandas</h3>
                        <p id="p-expli">Lorem ipsum dolor sit <br></br> amet. Lorem ipsum dolor sit amet.</p>
                    </div>
                </div>
            </a>
            <a href="maconneries">
                <div className="produit trente" id="deux">
                    <div id="div-expli2" className="div-expli">
                        <h3 id="h3-expli" className='h3-expli_even'>Maçonneries, Gros-oeuvre
                        </h3>
                        <p id="p-expli">Lorem ipsum dolor sit <br></br> amet. Lorem ipsum dolor sit amet.</p>
                        

                    </div>
                </div>
            </a>
            <a href="pergolas">
                <div className="produit trente" id="trois">
                    <div id="div-expli3" className="div-expli">
                        <h3 className="renova" id="h3-expli">Pergolas</h3>
                            <p id="p-expli">Lorem ipsum dolor sit <br></br> amet. Lorem ipsum dolor sit amet.</p>
                       
                    </div>
                </div>
            </a>
            <a href="extensions">
            <div className="produit dix" id="quatre">
                <div id="div-expli4" className="div-expli">
                    <h3 id="h3-expli" className='h3-expli_even'>Extensions d'habitats</h3>
                    <p id="p-expli">Lorem ipsum dolor sit <br></br> amet. Lorem ipsum dolor sit amet.</p>
                    
                </div>
            </div>
            </a>
        </div>
    </section>

    <section className="propos" id="propos">
        <div className="text-propos-home">
            <h4 className="h4-propos">NKI</h4>
            <p className="p-propos">Notre entreprise générale existe depuis plus de 30 ans sur le marché du bâtiment en
                Belgique et est à votre écoute pour tous vos pojets de constructions,
                de rénovations, de parachèvements intérieurs et exterieurs. <br></br>
                NKI, c’est aussi une large gamme de vérandas et pergolas à vivre, avec des produits de haute définition
                respectant l’environnement, et une main d’oeuvre de qualité.
            </p>

        </div>
        <div className="clients">
            <p className="client-compte" id="client-compte">+30</p>
            <div className="client-text">
                <p className="ans-p">ans</p>
                <p className="exp-p">d'experience</p>
            </div>

        </div>

        
        <img className="img-propos" src="/propos.jpg" alt="" />
    </section>
   
    <section className="information">
        <h3 className="h3-infor">Votre nouvel espace à vivre basse énergie</h3>
        <p className="p-infor">Les matériaux et nos profils menuiseries à haut rendement thermique sont de fabrication belge
            et vous permettent de profiter toute l’année de votre nouvel espace à vivre. Ils apportent une plus-value
            appréciable à votre habitat et sont en comformité avec le certificat PEB.</p>
    </section>

    <footer style={{ position:'initial'}} >
    <section className="don">
            <a className="footerimage1" href="#"><img src="/logoNKI.webp" alt=""></img></a>
            <div className="col col1">
                <h1 style={{height: '50.5px'}}>NOS PRODUITS ET ACTIVITÉS</h1>
                <div className="prod">
                    <ul className="ul1">
                        <li><a href="verandas">Vérandas</a></li>
                        <li><a href="pergolas">Pergolas</a></li>
                        <li><a href="maconneries">Maçonneries<br></br>Gros-oeuvre</a></li>
                        <li><a href="extensions">Extensions &<br />rénovations maison</a></li>
                        <li><a href="stores">Stores</a></li>
                    </ul>
                </div>
            </div>
            <div className="col col2">
                <h1 style={{height: '50.5px'}}>CONTACT</h1>
                <div className="grouping" style={{marginBottom:' 64px'}}>
                    <p style={{marginBottom: 10 + 'px'}}><b>No. de téléphone</b></p>
                    <p><u>Fixe</u>: 071/561010</p>
                    <p><u>Portable</u>: +32 494 52 66 11</p>
                    <p style={{marginBottom: 10 + 'px', marginTop: 20 + 'px'}}><b>E-mail:</b></p>
                    <p>info@n-k-i.com</p>
                </div>
            </div>
            <a className="footerimage2" href="#"><img src="/logoNKI.webp" alt=""></img></a>
        </section>
    </footer>
        </div>
    )
}
export default Home;