import './About.css'

function About(){
    setTimeout(() => {
        const menuBtn = document.querySelector('.menu-btn');
let menuOpen = false;

menuBtn.addEventListener('click', () => {
    if(!menuOpen) {
        menuBtn.classList.add('open');
        menuOpen = true;
    } else {
        menuBtn.classList.remove('open');
        menuOpen = false;
}
});

const anchor = document.getElementById('anchor');
const menu = document.getElementById('menu');
console.log(menu.style.display == '')

anchor.addEventListener('click', () => {
    console.log('clicked')
    if (menu.style.display == 'flex') {
        menu.style.display = '';
        console.log('removed')
    }
    else if(menu.style.display == '') {
        menu.style.display = 'flex';
        console.log('displayed')
    }

})
    }, 100);
    return(
        <div>
        <>
        <header>
        <nav>
            <input type="checkbox" id="check" />
            <label className="menu-btn" for="check">
                <div className="menu-btn__burger"></div>
            </label>
            <ul className="nav_links">
                <li className="li"><a className="hyper special" href="index.html"><img className="specimg" src="images/EBC_Logo.png" alt="" /></a></li>
                <li><a href="/">Accueil</a></li>
                <li className="dropli">
                    <div className="dropdown" id="anchor">
                        <a className="anchor">
                            Nos produits
                        </a>
                        <div className="menu" id="menu">
                            <div className="column">
                                <a href="verandas">Vérandas</a>
                                <a href="maconneries">Maçonneries<br></br>Gros-oeuvre</a>
                                <a href="stores">Stores</a>
                            </div>
                            <div className="column">
                                <a href="pergolas">Pergolas</a>
                                <a href="extensions">Extensions &<br />rénovations maison</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li><a href="About">À propos</a></li>
                <li><a href="realisations">Nos réalisations</a></li>
                <li><a href="Contact">Contact</a></li>
                <li className="img"></li>
            </ul>
            <div className="img1"></div>
        </nav>
        <div className="banderole">
            <div className="jvenm">devis & conseils gratuits</div>
            <div className="jvenm2">projets personalisés</div>
        </div>
    </header>
    
    <a className='form' href='contact'></a>

    <section className="sec sec1">
        <div className="about">
            <h1>À propos de NKI</h1>
            <p>Depuis maintenant déjà plus de 20 ans dans la construction, la rénovation et les extensions d’habitats, NKI vous propose quelques soient vos projets, son expérience et son savoir-faire afin de vous garantir un travail soigné selon les normes du bâtiment en vigeur.<br></br><br></br>Notre personnel qualifié et soigneux réalisera dans les moindres détails les exigences à apporter à votre projet en s’occupant de A à Z des états d’avancement de celui-ci.</p>
        </div>
        <img src="/public/ver-per.jng" alt="" />
    </section>


    <section className="sec sec3" id="sec3">
        <img id='img3' src="images/3d.png" alt="" />
        <div id='txt3' className="trid">
            <p>Tous nos partenaires - fournisseurs sont solides financièrement et sur le marché depuis des décennies tels que : <br></br><span style={{fontWeight: 500}}>ALIPLAST - AGC - SPRIMOGLAS - DHK - WIENERBERGER - KORAMIC - FACOZINC - APOK - ...</span> et la liste est longue. Ceux-ci sont avec nous pour vous garantir des produits de haute qualité et de grandes performances selon les normes CSTC et le label BENOR.</p>
        </div>
    </section>


    <section className="sec contact">
        <h1>Vous souhaitez des conseils et un devis gratuit?</h1>
        <div className="btn"><a href="contact">Contactez-nous</a></div>
    </section>


    <footer style={{ position:'initial'}} >
    <section className="don">
            <a className="footerimage1" href="#"><img src="/logoNKI.webp" alt=""></img></a>
            <div className="col col1">
                <h1 style={{height: '50.5px'}}>NOS PRODUITS ET ACTIVITÉS</h1>
                <div className="prod">
                    <ul className="ul1">
                        <li><a href="verandas">Vérandas</a></li>
                        <li><a href="pergolas">Pergolas</a></li>
                        <li><a href="maconneries">Maçonneries<br></br>Gros-oeuvre</a></li>
                        <li><a href="extensions">Extensions &<br />rénovations maison</a></li>
                        <li><a href="stores">Stores</a></li>
                    </ul>
                </div>
            </div>
            <div className="col col2">
                <h1 style={{height: '50.5px'}}>CONTACT</h1>
                <div className="grouping" style={{marginBottom:' 64px'}}>
                    <p style={{marginBottom: 10 + 'px'}}><b>No. de téléphone</b></p>
                    <p><u>Fixe</u>: 071/561010</p>
                    <p><u>Portable</u>: +32 494 52 66 11</p>
                    <p style={{marginBottom: 10 + 'px', marginTop: 20 + 'px'}}><b>E-mail:</b></p>
                    <p>info@n-k-i.com</p>
                </div>
            </div>
            <a className="footerimage2" href="#"><img src="/logoNKI.webp" alt=""></img></a>
        </section>
    </footer>
</>

    </div>
    )
   
}
export default About;