import './Realisations.css'

function Rea(){
    setTimeout(() => {
        
    






        var scrollTop = window.scrollY
        
        
        
        
        const menuBtn = document.querySelector('.menu-btn');
        let menuOpen = false;
        
        menuBtn.addEventListener('click', () => {
            if(!menuOpen) {
                menuBtn.classList.add('open');
                menuOpen = true;
            } else {
                menuBtn.classList.remove('open');
                menuOpen = false;
        }
        });
        
        const anchor = document.getElementById('anchor');
        const menu = document.getElementById('menu');
        console.log(menu.style.display == '')
        
        anchor.addEventListener('click', () => {
            console.log('clicked')
            if (menu.style.display == 'flex') {
                menu.style.display = '';
                console.log('removed')
            }
            else if(menu.style.display == '') {
                menu.style.display = 'flex';
                console.log('displayed')
            }
        
        })
        }, 100);
    setTimeout(() => {
        
        let photo = document.getElementsByClassName('card')
    let widthShow = document.getElementById('width-show')
    let show = document.getElementById('show')
    let blurBackground = document.getElementById('blur')
    
    for (let i = 0; i < photo.length; i++) {
        photo[i].addEventListener('click', ()=>{
            
            const style = window.getComputedStyle(photo[i])
            console.log(style.backgroundImage)
    
            const image = style.backgroundImage
    
            const url = image.slice(5, -2)
            console.log(url);
            show.style.background = url
            widthShow.src = url
           
            blurBackground.style.display = 'block'
            
            
        })
        
    }
    blurBackground.addEventListener('click',()=>{
        blurBackground.style.display = 'none'
    })
    }, 100);

    return(
        <div>
            <>
            <header>
            <nav>
            <input type="checkbox" id="check" />
            <label className="menu-btn" for="check">
                <div className="menu-btn__burger"></div>
            </label>
            <ul className="nav_links">
                <li className="li"><a className="hyper special" href="index.html"><img className="specimg" src="images/EBC_Logo.png" alt="" /></a></li>
                <li><a href="/">Accueil</a></li>
                <li className="dropli">
                    <div className="dropdown" id="anchor">
                        <a className="anchor">
                            Nos produits
                        </a>
                        <div className="menu" id="menu">
                            <div className="column">
                                <a href="verandas">Vérandas</a>
                                <a href="maconneries">Maçonneries<br></br>Gros-oeuvre</a>
                                <a href="stores">Stores</a>
                            </div>
                            <div className="column">
                                <a href="pergolas">Pergolas</a>
                                <a href="extensions">Extensions &<br />rénovations maison</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li><a href="About">À propos</a></li>
                <li><a href="realisations">Nos réalisations</a></li>
                <li><a href="Contact">Contact</a></li>
                <li className="img"></li>
            </ul>
            <div className="img1"></div>
        </nav>
        <div className="banderole">
            <div className="jvenm">devis & conseils gratuits</div>
            <div className="jvenm2">projets personalisés</div>
        </div>
    </header>

    <a className='form' href='contact'></a>

  <div className="background-rea">
        <h1>Nos Réalisations</h1>
    </div>
    <div className="text">
        <h2>Lorem Ipsum</h2>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita impedit, deleniti vero incidunt quos praesentium necessitatibus quibusdam nesciunt id amet, voluptatum voluptatibus cumque molestias natus consequuntur quam omnis accusantium dolorem! Ullam commodi est at voluptatibus, cumque unde, blanditiis repellat earum eaque sequi nesciunt illum, vitae autem facere? Officiis, illum beatae.</p>
    </div>
    
    <div className="pin_container" id="pin_container">
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_small" ></div>
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_medium"></div>
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_large" ></div>
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_small" ></div>
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_medium" ></div>
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_large"  ></div>
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_small"  ></div>
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_medium" ></div>
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_large"  ></div>
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_small"  ></div>
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_medium" ></div>
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_large"  ></div>
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_small"  ></div>
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_medium" ></div>
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_large"  ></div>
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_small"  ></div>
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_medium" ></div>
        <div style={{backgroundImage:'url("/ver-per.jpg")'}} className="card card_large"  ></div>
    </div>

    <div className="blur" id="blur">
        <div className="show" id="show">
            <img id="width-show" src="" alt="" />
        </div>
    </div>

    <footer className='footerrea' >
    <section className="don">
            <a className="footerimage1" href="#"><img src="/logoNKI.webp" alt=""></img></a>
            <div className="col col1">
                <h1 style={{height: '50.5px'}}>NOS PRODUITS ET ACTIVITÉS</h1>
                <div className="prod">
                    <ul className="ul1">
                        <li><a href="verandas">Vérandas</a></li>
                        <li><a href="pergolas">Pergolas</a></li>
                        <li><a href="maconneries">Maçonneries<br></br>Gros-oeuvre</a></li>
                        <li><a href="extensions">Extensions &<br />rénovations maison</a></li>
                        <li><a href="stores">Stores</a></li>
                    </ul>
                </div>
            </div>
            <div className="col col2">
                <h1 style={{height: '50.5px'}}>CONTACT</h1>
                <div className="grouping" style={{marginBottom:' 64px'}}>
                    <p style={{marginBottom: 10 + 'px'}}><b>No. de téléphone</b></p>
                    <p><u>Fixe</u>: 071/561010</p>
                    <p><u>Portable</u>: +32 494 52 66 11</p>
                    <p style={{marginBottom: 10 + 'px', marginTop: 20 + 'px'}}><b>E-mail:</b></p>
                    <p>info@n-k-i.com</p>
                </div>
            </div>
            <a className="footerimage2" href="#"><img src="/logoNKI.webp" alt=""></img></a>
        </section>
    </footer>
</>

        </div>
    )
}

export default Rea;