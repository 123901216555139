import React from 'react';
import './App.css';
import ReactDOM from 'react-dom'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Removed Route import

import Home from './Home';
import About from './About';
import Contact from './Contact';

import Rea from './Realisations';
import Pergolas from './Pergola';
import Veranda from './Veranda';
import Extentions from './extention';
import Grosoeuvre from './Grosoeuvre';
import Stores from './Stores';



function App() {
  return (
    <Router>
     
        <Routes>
          <Route exact path='/' Component={Home} /> {/* Use component instead of Component */}
          <Route path='/about' Component={About} /> {/* Use component instead of Component */}
          <Route path='/contact' Component={Contact} /> {/* Use component instead of Component */}
          <Route path='/pergolas' Component={Pergolas} /> {/* Use component instead of Component */}
          <Route path='/verandas' Component={Veranda} /> {/* Use component instead of Component */}
          <Route path='/extensions' Component={Extentions} /> {/* Use component instead of Component */}
          <Route path='/maconneries' Component={Grosoeuvre} /> {/* Use component instead of Component */}
          <Route path='/realisations' Component={Rea} /> {/* Use component instead of Component */}
          <Route path='/stores' Component={Stores} /> {/* Use component instead of Component */}
         
          
        </Routes>
  
    </Router>
  )
}

export default App;
